/*==================== GOOGLE FONTS ====================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  font-family:  "Poppins", sans-serif !important;
  color: #686b77 !important;
  font-weight: 400!important;
}
p{
  font-family:"Poppins", sans-serif !important;
}