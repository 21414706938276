.box-Img-one{
    width: 100%;
    height: 320px;
}

.box-Img-one {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
  
  .category-one-box:hover .box-Img-one {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

  }

.box-Img-two{
    width: 100%;
    height: 320px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    object-fit: cover;
}

.category-two-box:hover .box-Img-two {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
.box-Img-three{
    width: 100%;
    height: 360px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    object-fit: cover;
}
  
  .category-three-box:hover .box-Img-three {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .hover-category{
    position: absolute;
    background: rgba(1,1,1,0.5);
    color: #fff;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    line-height: 300px;
    text-align: center;
    font-size: 30px;
    z-index: 2
  }
  .hover-category:hover{
    opacity: 1
  }
.section-one-category{
    margin-bottom: 30px;
    width: 100%;
}
.category-one-box{
    position: relative;
    display: inline-block;
    overflow: hidden
}
.category-two-box{
    position: relative;
    display: inline-block;
    overflow: hidden
}
.category-three-box{
     position: relative;
    display: inline-block;
    overflow: hidden
}
.section-two-category{
    width: 100%;
    padding-bottom: 8%;
}
.category-one-box,.category-two-box,.category-three-box,.category-four-box,.category-five-box{
  overflow: hidden;
  border-radius: 12px;  
  width: 100%;
  box-shadow: 0 2px 27px -1px #00000017;
}

.rentCategory-text{
  color: #000000;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 88%;
    background-color: #ffffff;
    padding: 10px 0 10px 16px;
    font-weight: 600;
}

.category-container.container{
 margin-left: 10%;
}

.top-category-headin{
    margin-left: 12%;
    padding-top: 30px;
}
.top-categ-head{
    color: #FF5D22;
}
@media (max-width: 768px) {
  .box-Img-one{  
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .box-Img-two {
    width:100%;
    height: 200px;
  }
  .box-Img-three{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .category-three-box{
    margin-top: 20px;
  }
  .category-one-box{
    margin-top: 20px;
  }
  .category-two-box{
    margin-top: 20px;
  }
  .rentCategory-text {
    height: 78px;
    padding: 8px 12px;
    top: 81%;
  }
}