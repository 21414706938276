.card-category-container{
    border: 1px solid #A7A7A7;
    margin-bottom: 20px;
}
   .catg-imgs{
       width: 100%;
       height: 200px;
   }
   .card.text-center.category{
       width: 100%;
   }
   .rate-imgs{
       height: 20px;
       width: 20px;
   }
   .catg-fav{
       display: flex;
   }

   .location-catg{
    color:#A7A7A7;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -16px;
   }
 .yourwallet {
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5rem 1.25rem;
    padding: 10px;
    border-radius: 8px;
}
.yourwallet h5 {
    color: rgb(137, 26, 28);
}
.yourwallet  .wallet-address {
    color: rgb(0, 0, 0);
    opacity: inherit;
    font-weight: 600;
    overflow-wrap: break-word;
    font-size: 21px !important;
    border-bottom: 5px solid rgb(137, 26, 28);
    outline: none;
    text-align: left;
}
.avatar{
    height: 200px;
    width: 200px;
    border-radius: 10px;
}
.my-account .mb20 .flex-column .nav-item  a{
  padding: .6rem .8rem;  
}
.my-account{
    [class*="col-lg-6"] {
        display: flex;
        margin-bottom: 1rem;
    }
}