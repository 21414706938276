.card-category-container{
    border: 1px solid #A7A7A7;
    margin-bottom: 20px;
}
   .catg-imgs{
       width: 100%;
       height: 200px;
   }
   .card.text-center.category{
       width: 100%;
   }
   .rate-imgs{
       height: 20px;
       width: 20px;
   }
   .catg-fav{
       display: flex;
   }
   .location-catg{
    color:#A7A7A7;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -16px;
   }