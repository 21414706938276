.RentPage-wrapper-container{
//  width:100%;
//  margin-bottom: 30%;
// //  background-image: url('../../assets/Images/insideBgd.jpg');
// //  background-repeat: no-repeat;
// //  background-size: cover;
//  min-height: 50vh;
}

.align-items-center{
    display: flex;
    justify-content: 'center';
    align-items: 'center';
}


.heading-txt-style {
    text-align: center;
    color: #fff;
    font-size: 25px;
    margin-bottom: 4px;
    font-weight: 500;
    text-shadow: 2px 3px 4px black;
}

.table-category{
    // display: flex;
    // justify-content: center;
    // position: relative;
}
.table-catg-details{
    // position: absolute;
    // top:100px;
}

.RentPage-wrapper-container td, th {
    border: 1px solid #000000;
    text-align: left;
    padding: 8px;
    background-color: #ffffff;
  }
  th{
      color: #000000;
  }

.main-rent-search{
    display: flex;
    justify-content: center;
}