.category-select-txt{
    color: #FF5D22;
}


.form-footer-style{
  margin-top:100%;
}

.submit-form-btn{
    border: none;
    background-color: #FF5D22;
    width: 110px;
    height: 30px;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
}

.field-note-txt{
    font-size: 14px;
    color: 	#727272;
}

.upload-pic{
    width: 40px;
    margin-top: 25px;
    margin-left: 15px;
    height: 40px
}

.location-flex{
    display: flex;
}
.location-span{
    border: 1px solid #FF5D22;
    color: #FF5D22;
}

.r-col-d-3.img-upload{
 border: 1px solid #a7a7a7;
 width: 100px;
 height: 100px;
 margin: 10px;
}

.location-txt{
    margin-right: 20px;
}
.location-flex{
    margin-bottom: 20px;
    margin-top: 20px;
}

.address-flex-style{
    display: flex;
    margin-left: -18px;
}
.form-control.bottom-field{
 margin-top: 10px;
 margin-bottom: 10px;
}

.col-md-6.pinStyle{
    margin-left: -18px;
}
