.subctg-txt{
    color:#A7A7A7;
    text-decoration: none;
    list-style: none;
}

.subctg-txt:hover{
    columns: #880000;
    text-decoration: none;
}

.sideBar-container{
    margin-left: 40%;
    width: 70%;
}

.subctg-list{
    list-style: none;
    text-decoration: none;
}
.allCategory-txt{
    font-size: 20px;
    font-weight: 600;
}
.sub-category{
    font-size: 18px;
}
.menu.moz-scroll-width{
margin-left: 100px;
}
.menu.moz-scroll-width ul li{
    text-decoration: none;
    list-style: none;
}